<template>
  <div id="password-recovery">
    <div class="container">
      <h1 class="title">Restablecer contraseña</h1>
      <div>
        <div class="content">
          <p>
            Passwords must be at least 8 characters long. Remember that it is
            not recommended that you reuse usernames and passwords for different
            services and websites.
          </p>
        </div>
        <form @submit.prevent="submit">
          <b-field>
            <b-input
              v-model="pass1"
              type="password"
              password-reveal
              placeholder="Nueva contraseña"
              :disabled="loading"
            />
          </b-field>
          <b-field>
            <b-input
              v-model="pass2"
              type="password"
              password-reveal
              placeholder="Confirmar nueva contraseña"
              :disabled="loading"
            />
          </b-field>
          <b-button
            native-type="submit"
            type="is-primary"
            :class="{ 'is-loading': loading }"
            size="is-medium"
            rounded
            :disabled="loading"
            >Restaurar contraseña</b-button
          >
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { wpService } from "@/services/wp";
export default {
  name: "password-recovery",

  data() {
    return {
      user_id: Number(this.$route.query.id) || null,
      key: this.$route.query.key || null,
      pass1: "",
      pass2: "",
      verified: null,
      loading: false,
      result: null,
      errors: false,
    };
  },

  created() {
    if (!this.user_id || !this.key || !this.key.length) {
      // this.$router.push({ name: "home" });
    } else {
      this.verify();
    }
  },

  methods: {
    alertCustomError(message = null) {
      this.$buefy.dialog.alert({
        // title: "Error",
        message: message ? message : "Something's not good",
        type: "is-danger",
      });
    },
    submit() {
      if (this.pass1 !== this.pass2) {
        this.alertCustomError("Las contraseñas no coinciden.");
        return;
      }
      if (this.pass1.length < 8) {
        this.alertCustomError(
          "La contraseña debe contener al menos 8 caracteres.",
        );
        return;
      }
      this.loading = true;
      wpService.account
        .recoveryComplete(this.user_id, this.key, this.pass1)
        .then(() => {
          this.$buefy.dialog.alert({
            message:
              "<strong>¡Cambio de contraseña exitoso!</strong><br>Ya puedes iniciar sesión con tu nueva contraseña.",
            confirmText: "OK!",
          });
          this.$router.push({ name: "home" });
        })
        .catch(error => {
          this.alertCustomError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    verify() {
      return wpService.account
        .recoveryVerify(Number(this.user_id), this.key)
        .then(responose => {
          console.log(responose);
        })
        .catch(error => {
          console.log(error);
        });
    },
  },
};
</script>
